<script>
import imageLandingPageBackground from "@/assets/images/login&home_background.png";
import imageLogo from "@/assets/images/logo_timi.png";

import CommonLayout from "./CommonLayout.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    CommonLayout,
  },
  data() {
    return {
      imageLandingPageBackground,
      imageLogo,
    };
  },
};
</script>

<template>
  <CommonLayout
    :title="title"
    color="light"
    :headerPlaceholder="false"
    transparentHeader
  >
    <div class="layout">
      <div class="background">
        <img :src="imageLandingPageBackground" class="background_image" />
      </div>
      <div class="body">
        <div class="slot">
          <div class="inner">
            <slot />
          </div>
        </div>
        <img class="logo" :src="imageLogo" />
      </div>
    </div>
  </CommonLayout>
</template>

<style scoped lang="less">
@import "@/styles/design.less";

.layout {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #233176, #37335d);

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .background_image {
      display: block;
      width: 100%;
    }
  }

  .body {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .slot {
      flex: 1 1;
      width: 100%;
      min-height: 180vw;

      .inner {
        padding-top: 98%;
      }
    }

    .logo {
      width: 70px;
      display: block;
      margin-bottom: 84px;
    }
  }
}
</style>
