<script>
import LandingPageLayout from "@/layouts/LandingPageLayout.vue";

import slogan from "@/assets/images/home/slogan.png";
import button1 from "@/assets/images/home/button_1.png";
import button2 from "@/assets/images/home/button_2.png";

export default {
  components: {
    LandingPageLayout,
  },
  data() {
    return {
      slogan,
      button1,
      button2,
    };
  },
  methods: {
    handleBet() {
      this.$router.push("/choujiang/guessResult");
    },
    handleEncourage() {
      this.$router.push("/choujiang/encourage");
    },
  },
};
</script>

<template>
  <LandingPageLayout title="共创杯.竞猜互动">
    <div class="form">
      <div class="button" @click="handleBet">
        <img :src="button1" class="button_img" />
      </div>
      <div class="button" @click="handleEncourage">
        <img :src="button2" class="button_img" />
      </div>
      <img :src="slogan" class="slogan" />
    </div>
  </LandingPageLayout>
</template>

<style scoped lang="less">
.form {
  margin: auto;
  margin-top: 5vw;

  img {
    display: block;
  }

  .button {
    width: 100%;
    height: 25vw;
    position: relative;
    overflow: hidden;
    z-index: 10;

    .button_img {
      width: 116%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  .slogan {
    width: 94vw;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10vw;
    position: relative;
    z-index: 1;
  }
}
</style>
